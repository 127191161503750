

/* ------------------------------ General ----------------------------- */


.lah_gradient {
  background: var(--lah-gradient);
}

.primary-color {
  background: #00cac2;
  
}

.secondary-color {
  background: #4d71e3;
}

.nopadding {
  padding: 0;
  margin: 0;
}

.bg-light-green{
  background-color:var(--off-cyan);
}



/* ------------------------------ Login ----------------------------- */
.radius {
  border-radius: 1rem;
}

.rounded-rad {
  border-radius: 20px;
}

.bg-cyan {
  background-color: var(--off-cyan);
}


.qty-product {
  font-size: 8pt;
}

span.form-check-label {
  font-size: 10pt;
}

.form-control {
  border-radius: 1rem;
 }
 
 .form-control, input {
   height: 2.5rem;
  }
 /* .form-control:focus {
   box-shadow: inset 0 0 0;
 } */
 .form-control:focus {
    box-shadow: none;
    /* background-color: rgb(207, 213, 214); */
    /* border-bottom: 3px solid #1ab3ce; */
    outline: none;
}
 
 .input-group-text {
   width:3rem;
   justify-content: center;
   border-radius: 10px;
 }

 .textarea {
  height: 100px;
}
/* ------------------------------ Login/Left Side Image ----------------------------- */

  .overlay-cont {
    position: relative;
    z-index: 10;
  }

  .overlay{
    position:absolute;
    z-index:100;
  }

  .overlay-span {
    position:absolute;
    top:57%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }

  .img-hero {
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    /* opacity:0.75; */
  }

  .dna {
    width: 30%;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: .75;
  }

/* ------------------------------ Sidebar ----------------------------- */

.primary-text {
  font-family:var(--body-font);
  color:black;
}

.second-text {
  color: var(--second-text-color);
}

.primary-bg {
  background-color: var(--primary-color);
}

.primary-gradient {
  background-color: var(--lah-gradient);
}

.secondary-bg {
  background-color: var(--second-bg-color);
}

.rounded-full {
  border-radius: 100%;
}

#wrapper {
  /* overflow-x: hidden; */
  background: #e4f7f7;
}
#md-navbar {
  z-index: 1;
}
#sidebar-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  z-index: 1;
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.3s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.3rem 1.25rem;
}

#sidebar-wrapper .sidebar-heading .brand-logo-white{
  width: 12rem;
}
.card-title {
  font-size: 1.25rem;
}

/* .card-title:hover {
  color: #00cac2;
} */

.book-service-subheader {
  font-size: 1.125rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

.sidebar-item{
  font-size: 1.5rem;
  font-family:  'Montserrat', sans-serif;;
}
/* #page-content-wrapper {
  min-width: 1200px;
  height: auto;
} */


#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

#menu-toggle {
  cursor: pointer;
}

.list-group-item {
  border: none;
  padding: 20px 30px;
}

.list-group-item.active {
  background-color: transparent;
  color: var(--main-text-color);
  font-weight: 700;
  font-family: var(--body-font);
  border: none; 
}

.list-group-item:hover{
  width: 100%;
  background-color: rgba(221, 214, 221, 0.5);
  border-radius: 0 10px 10px 0;
  
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 105%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -17rem;
  }
}

/* Spinner */

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  position: relative;
  top: 50px;
  margin: auto;
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #34bbf0; /* Black */
  border-radius: 50%;
  animation: spinner .5s linear infinite;
}


/* ------------------------------ Footer ----------------------------- */



/* ------------------------------ Admin ----------------------------- */

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
}

/* ------------------------------ Home ----------------------------- */

#main-container {
  display: grid;
  place-items: center;
  width: 75%;
  margin: 0 auto;
  /* padding: 20px; */
}

.btn-main {
  background-color: #46a049;
  color: #ffffff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
}
.btn-main:hover {
  background-color:#4ab24e;
}



/* ------------------------------ Typography ----------------------------- */

.confirm-details {
  font-size: 8pt;
}

/* ------------------------------ Header ----------------------------- */

.z-top{
  z-index: 100;
}

.z-mid {
  z-index: 50;
}

.z-bottom {
  z-index:10;
}

/* ------------------------------ Main: Table ----------------------------- */
.scroll {
  max-height: 40vh;
  overflow-y: auto;
}

.scrollbar-div::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

.scrollbar-div::-webkit-scrollbar-thumb {
  background-color:lightgray;
  border-radius: 10px;
  height: 2px;
  width:5px;
}
/* .scrollbar-div {
  height: 100vh;
} */

table {
  border-collapse: separate; 
  border-spacing: 0;
  padding: 16px;
  margin: 0 auto;
  text-align: left;
}
thead {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  top:0;
  position: sticky;
  border-bottom: 5px solid black;
}
th, tr, td {
  padding: 17px;
  font-size: .8rem;
  vertical-align: middle;
  text-align: left;
}
tbody tr:hover {
  background: #e6f7ff;
}

.table-btn {
  text-align: center;
}

#main-md-table{
  overflow-x:scroll;
  margin: 0 auto;
  border-radius: 15px;
  height: 100%;
}

#main-md-table .std-table-container {
  height: 70vh !important;
  width: 100vw;
  max-width:100%;
  position: relative;
  border-radius: 15px;
}

#main-md-table .std-table-container table {
  border-radius: 15px;
  height: 50%;
}


#main-md-table .std-table-container table tr {
  padding: 8px;
  font-size: 10pt;
  min-height: fit-content;
  /* vertical-align: middle; */
  margin: 0 auto;
}

#main-parent-container{
  height: 100vh !important;
  width: 100vw;
  max-width:100%;
 
}

.table-card-container {
  overflow: hidden;
  height: 500px;
  overflow: auto;
  margin: 0 auto;
  padding: 8px;
  width: 90%;
} 

/* ------------------------------ Images ----------------------------- */
.no-data-img {
  margin: 50px auto;
  width: 200px;
  height: 200px;
}

.card-img-top {
  width: 100%;
  height: 100%;
}
/* .service-cards {
  height: 50px;
} */

.cta-text-service {
  font-size: 0.75rem;
}

/* ------------------------------ Toast ----------------------------- */

.notification-container {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
}

.top-right {
  top: 12px;
  right: 12px;
  transition: transform .6s ease-in-out;
  animation: toast-in-right .7s;
}

.search {
  border: none;
  outline: none;
  padding: 10px;
  width: 150px;
  line-height: 30px;
  margin-bottom: 30px;
}

.unclickable {
  pointer-events:none;
  opacity: 0.2;
}


.overlay {
  position: 'absolute';
  background-color: 'rgba(255, 100, 100, 0.85)';
  padding: '2px 10px';
  color: 'white';
  border-radius: 3;
}

/* .container {
  height: 100vh;
} */


/* #mapContainer {
  width: 90%;
  height: 50px;
} */

/* #map {
  position: relative;
  width: 100%;
  height: 32vh;
  min-height: 210px;
  margin: 16px auto;
  border: 1px solid #eeeeee;
  border-radius: 16px;
  overflow: hidden;
} */

.slots {
  height: 20rem;
}


.main-wrapper {
  /* height: 60vh; */
  width: 100%;
  height: 500px;
  position: relative;
  padding: 2px;
  /* filter: drop-shadow(-1px 5px 3px #ccc); */
}
.info-wrapper {
  margin-top: 15px;
}
.map-details {
  text-align: center;
  font-size: 1.2em;
}
.map-details span {
  font-weight: bold;
}
.search-input {
  font-size: 1.2em;
  width: 100%;
  border-radius: 10px;
  border: 0;
  box-shadow: none;
}


.toast-logout-notification {
  width: 500px;
  right: 200px;
}

.toast-logout-notification-body {
  margin-bottom: 10px;
}

/* ------------------------------ Spinner ----------------------------- */

.flex {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}


.border-secondary,
.loader-demo-box {
  border-color: #a3a4a5 !important
}

.rounded,
.loader-demo-box {
  border-radius: 0.25rem !important
}

.loader-demo-box {
  width: 100%;
  height: 200px
}

.jumping-dots-loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto
}

.jumping-dots-loader span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: var(--primary-color);
  margin: 35px 5px
}

.jumping-dots-loader span:nth-child(1) {
  animation: bounce 1s ease-in-out infinite
}

.jumping-dots-loader span:nth-child(2) {
  animation: bounce 1s ease-in-out 0.33s infinite
}

.jumping-dots-loader span:nth-child(3) {
  animation: bounce 1s ease-in-out 0.66s infinite
}

@keyframes bounce {

  0%,
  75%,
  100% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0)
  }

  25% {
      -webkit-transform: translateY(-20px);
      -ms-transform: translateY(-20px);
      -o-transform: translateY(-20px);
      transform: translateY(-20px)
  }
}





/* @media (max-width:991.98px) {
  .padding {
      padding: 1.5rem
  }
}

@media (max-width:767.98px) {
  .padding {
      padding: 1rem
  }
}

.padding {
  padding: 5rem
}

.card {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none
} */


.file-upload-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 250px;
  margin: 0 auto;
  min-width: 80px ;
  margin: 0 20px;
  padding: 16px 32px;
}

.cart {
  width: 50rem;
  max-width: 90%;
  margin: 2rem auto;
}

.cart p {
  text-align: center;
}

.cart ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.cart li {
  padding: 1rem;
  margin: 1rem 0;
  border: 1px solid #00179b;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


button .add {
  background-color: #40c0f0;
  width: 1.5rem;
}
button .remove {
  background-color: #f04040;
  width: 1.5rem;
}
button .badge {
  background-color: #f04040;
  border: none;
  color: #ffffff;
  width: 1.5rem;
}

.continue-shopping {
  display: flex;
  height: inherit;
  align-items: center;
}

.continue-shopping .arrow-icon {
  width: 2.2rem;
  height: 2.2rem;
}

.continue-shopping h3 {
  font-size: 2rem;
  text-transform: capitalize;
  color: var(--main-color);
  margin-left: 1.5rem;
  font-weight: 400;
}

.cart-icon {
  display: flex;
  text-align: end;
  margin-right: 2rem;
  align-items: center;
  position: relative;
  justify-content: flex-end;
}

.cart-icon img {
  width: 5rem;
  height: 5rem;
  color: #2f80ed;
}

.cart-icon p {
  position: absolute;
  width: 4rem;
  height: 4rem;
  right: -1.2rem;
  top: 0.9rem;
  border-radius: 50%;
  background: #99cbf7;
  color: #fff;
  box-sizing: border-box;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-color);
}

.main-cart-section h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  text-transform: capitalize;
  color: rgba(41, 41, 41, 1);
  margin-bottom: 1rem;
}

.main-cart-section .total-items {
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  text-transform: capitalize;
  color: var(--main-color);
  margin-bottom: 5rem;
}

.total-items-count {
  font-weight: bold;
  color: var(--main-color);
}

/* cart main div start  */

.main-cart-section .cart-items {
  width: 100%;
  height: 62rem;
  background: linear-gradient(
    103.49deg,
    #ffffff -28.13%,
    rgba(242, 247, 255, 0.5) 116.84%
  );
  box-shadow: rgba(0, 0, 0, 0.08) 0rem 0.4rem 1.2rem;
  border-radius: 2rem;
  margin: auto;
  margin: auto;
  display: grid;
  place-items: center;
}

.cart-items hr {
  margin: 2rem auto;
  overflow-y: scroll;
}

.cart-items-container {
  width: 90%;
  height: 56rem;
}

.items-info {
  width: 100%;
  height: 11rem;
  /* background-color: lavender; */
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.items-info .product-img img {
  width: 16rem;
  height: 11rem;
  filter: drop-shadow(0rem 0.4rem 1rem #f1f7ff);
  border-radius: 1rem;
}

.items-info .title {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.items-info .title h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 2.2rem;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: var(--main-color);
}

.items-info .title p {
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  text-align: left;
  color: var(--primary-color);
}

.add-minus-quantity {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.add-minus-quantity button {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.add-minus-quantity input {
  width: 6rem;
  height: 3rem;
  border: 0.141rem solid var(--primary-color);
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  text-align: center;
  text-transform: capitalize;
  color: var(--primary-color);
  margin: 0 1rem;
  border-radius: 0.5rem;
  outline: none;
  background-color: transparent;
}

.add-minus-quantity input:focus {
  outline: none;
}

.price {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.price h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  text-transform: capitalize;
  color: var(--main-color);
}

.remove-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5rem;
}

.remove-item button {
  border: none;
  background-color: transparent;
}

.card-total {
  width: 95%;
  margin-top: 4rem;
  text-align: right;
}

.card-total h3 {
  font-style: 200;
  font-size: 2.58rem;
  line-height: 3.2rem;
  text-transform: capitalize;
  color: #606166;
}

.card-total h3 span {
  font-style: normal;
  font-weight: bold;
  font-size: 2.8rem;
  line-height: 3.2rem;
  text-transform: capitalize;
  color: #000000;
  margin-left: 1rem;
}

.card-total button {
  border: none;
  font-size: 1.6rem;
  padding: 1rem 3rem;
  color: #fff;
  background-color: #349bf3;
  text-transform: uppercase;
  margin-top: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  letter-spacing: 1px;
}

.pointer {
  cursor: pointer;
}

/* hover effects  */
.fa-trash-alt {
  color: #ed4848;
}

.fa-plus:hover {
  color: #42c157;
}

.fa-minus:hover {
  color: #ffb800;
}

.fa-plus,
.fa-minus {
  color: var(--icon-color);
}

.card-total button:hover {
  background-color: #0077dc;
}

.card-total .clear-cart {
  margin-left: 3rem;
  background-color: rgb(209, 61, 61);
}

.card-total .clear-cart:hover {
  background-color: rgb(197, 5, 5);
}

.back-icon {
  font-size: 1.5rem;
}
.page-title-header, .page-title-subheader {
  font-family: 'Montserrat', sans-serif;
}

.page-title-subheader {
  font-size: 0.9em;
  font-weight: 400;
}

.service-header .no-px {
  font-size: 10pt;
  color: #000000;
}

.book_service_btn {
  border-radius: 5px;
  min-width: 270px;
  pointer-events: none;
}

.hr-text {
  display: flex;
  flex-direction: row;
  color:rgb(110, 108, 108);
}
.hr-text:before, .hr-text:after{
  content: "";
  flex: 1 1;
  border-bottom: 1px solid rgb(201, 195, 195);
  margin: auto;
}
.hr-text:before {
  margin-right: 10px
}
.hr-text:after {
  margin-left: 10px
}


.main-wrapper {
  height: 60vh;
  margin: 10px 50px;
  filter: drop-shadow(-1px 5px 3px #ccc);
}
.info-wrapper {
  margin-top: 15px;
}
.map-details {
  text-align: center;
  font-size: 1.2em;
}
.map-details span {
  font-weight: bold;
}
.search-input {
  font-size: 1.2em;
  width: 80%;
}


.fc {
  height: 150rem;
}

.css-1s2u09g-control {
  border-radius: 15px;
}


.fc-v-event .fc-event-title, .fc-timegrid-event .fc-event-time {
  color:rgba(124,66,0,0.86);
}

.align-left {
  text-align: left;
  line-height: 2;
}

#map {
  width: calc(100% - 10px);
  height: 400px;
  margin: 16px auto 10px;
  border: 1px solid #eeeeee;
  border-radius: 16px;
  box-shadow: 1px 3px 6px #dcdcdc;
}


.calendar-container {
  margin: auto;
  height: 800px;
  border-radius: 20px;
}

.fc .fc-col-header-cell-cushion { /* needs to be same precedence */
  padding-top: 5px; /* an override! */
  padding-bottom: 5px; /* an override! */
  background-color: rgb(34, 33, 33);
  width: 100%;
  color: white;
}

.fc .fc-button {
  background-color: rgb(34, 33, 33);
}

th .fc-timegrid-axis {
  background-color: rgb(34, 33, 33);
}

.fc-day-grid-event .fc-content{ 
  white-space: normal!important; 
  color: black;
}
.fc-h-event .fc-event-title {
  overflow: visible;
  color: black;
  white-space: pre-line;
}

.fc-v-event .fc-event-title-container {
  overflow: visible;
  white-space: normal;
}

.truncate{
  max-width:12rem;
  text-overflow: ellipsis;
  cursor: pointer;
  word-break: break-all;
  overflow:hidden;
  white-space: nowrap;
}
.truncate:hover{
  overflow: visible; 
  white-space: normal;
}
.text-greetings {
  color:#00cac2;
  text-shadow: 0px 2px 2px rgba(241, 236, 236, 0.4); 
}

.greeting {
  font-size: 1rem;
}

.sub-greeting {
  font-size: 0.8rem;
}
.trex {
  width: 2rem;
}

.toggle-arrow {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.nav-right-mobile {
  display: none;
}


.oops {
  font-weight: 900;
}
.dropdown-menu {
  max-height: 280px;
  overflow-y: auto;
}

.navbar-nav .nav-item:hover {
  background-color: rgba(180, 190, 203, 0.4);
}


.card-hover{
  /* border-radius: 4px;
  background: #fff; */
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  /* padding: 14px 80px 18px 36px; */
  cursor: pointer;
}

.skip-link {
  cursor: pointer;
  margin: 0 5px;
}


.card-hover:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.card-text {
  /* height: 5rem; */
  text-align: center;
  font-size: 0.5rem;
}






.product-container {
  overflow: scroll;
  height: 600px;
  width: auto;
}

.dropdown-menu{
  width: 215px;
  max-height:200px;
  overflow-y:scroll; 
}

.upload-img {
  width: 50px;
  height: 50px;
}

.inp {
  border:none;
  border-bottom: 1px solid #1890ff;
  padding: 5px 10px;
  outline: none;
}

.btn-action {
  width:250px;
}

[placeholder]:focus::-webkit-input-placeholder {
  transition: text-indent 0.4s 0.4s ease; 
  text-indent: -100%;
  opacity: 1;
}

.card-px-bookings:first-child {
  /* border-top: 1px solid gray; */
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.card-px-bookings:last-child {
  /* border-bottom: 1px solid gray; */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.page-link {
  text-decoration: underline;
  color: rgb(21, 93, 226);
  cursor: pointer;
}

.page-title-subheader {
  font-weight: 500;
}

label {
  font-weight: 600;
  color: #555;
}

.required::after{
  content:" *";
  color: red;
}

#md #table {
  display: block;
  padding: 0;
  margin: 0 0 24px;
  overflow: auto;
  min-height: 400px;
}

#md #table a {
  color: inherit;
}

#md #table .row {
  width: 100%;
  display: grid;
  grid-template-columns: auto;
}

#md #table .row {
  padding: 8px 6px;
  border-bottom: 1px solid #eee;
  text-align: center;
}

#md #table .row:last-child {
  border-bottom: none;
}

#md #table .column:last-child {
  text-align: right;
}

#md #table .column.action-header {
  text-align: center;
}

#md #table .column {
  font-size: 8pt;
  text-align: left;
  margin: 0;
  padding: 0 24px;
  white-space:pre;
  overflow: hidden;
  text-overflow: ellipsis;
}

#md #table .column.no-underline a {
  text-decoration: none;
}

#md #table .column.multi-link a {
  margin: 0 1px;
}

#md #table div.column {
  white-space: normal;
}

#md #table #headers .column {
  font-weight: 600;
  font-size: 10pt;
}


#md #table.patients .row {
  min-width: 1220px;
  grid-template-columns: repeat(8, 2fr);
}

#md #table.lab-results .row {
  min-width: 1320px;
  grid-template-columns: repeat(9, 2fr);
}

#md #table.bookings .row {
  min-width: 1520px;
  grid-template-columns: repeat(8, 2fr);
}

.tbl-container {
  width: 400px;
  margin-top: 10px;
  margin-left: 10px;
}

.bdr {
  border-radius: 6px;
  overflow: hidden;
}

/*** book service ***/

#book__service .container-fluid {
 /* width: 100%; */
 /* background-color: #00179b; */
 margin: 0 auto;
 place-items: center;
 place-content: center;
}

/* ------------------------------ Booking Confirmation ----------------------------- */

#booking__confirmation .confirmation-labels {
  font-size: 10pt;
  padding: 0;
  margin: 2px auto;
}

.conf-values {
  font-size: 10pt;
}

.truncate{
  max-width:5rem;
  text-overflow: ellipsis;
  cursor: pointer;
  word-break: break-all;
  overflow:hidden;
  white-space: nowrap;
}
.truncate:hover{
  overflow: visible; 
  white-space: normal;
}

/* ------------------------------ Buttons , Badges and Links ----------------------------- */
.cta {
  cursor: pointer;
  display: block;
  width: 100%;
  font-size: 10pt;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  padding: 16px 0;
  border: none;
  border-radius: 16px;
  background-color: #4d70e2;
  background-image: linear-gradient(225deg, #00cac2, #4d70e2);
  box-shadow: 1px 3px 6px #dcdcdc;
}

.cta:hover {
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.return-link {
  text-decoration: underline;
  color:#2f80ed;
  cursor: pointer;
  font-weight: 500;
}
.return-link:hover{
  color:#0b2bde;
}

.btn-sec-color {
  background: var(--sec-color);
}

.btn-sec-color:hover {
  background:  #4469e4;
}

.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
  height: 3rem;
}

.btn-view, .btn-text, i {
  font-size: 10pt;
}

.btn {
  box-shadow: none;
  border-radius: 10px;
}

.link-text{
  font-size: .8rem;
  color: white;
}

.btn:hover {
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  transform: scale(1.1);
}

.btn-service {
  padding: 0.75rem;
}

.cta-action {
  font-size: .8rem;
  padding: 0 8px;
  cursor: pointer;
}

.loc-icon {
  font-size: 1rem;
}

.upload-icon {
  font-size: 2.5rem;
  font-weight: 200;
}

.badge {
  font-size: .75rem;
}

.bg-pending {
  background: #f2a639;
  color: #744f1c;
}
.bg-confirmed {
  background: #0d890b;
  color: #084d07;
}
.bg-cancelled {
  background: red;
}
.bg-enroute {
  background: #1f91ce;
  color: #0d3c55;
}
.bg-arrived {
  background: rgb(8, 11, 207);
  /* color: rgb(7, 8, 77); */
}
.bg-started {
  background: #e3f11c;
  color: #666d0d;
}
.bg-done {
  background: #33c836;
  color: #0c380d;
}
.bg-specimen-to {
  background: rgb(58, 219, 219);
  color: rgb(25, 97, 97);
}
.bg-partial-res {
  background: #fa68b3;
  color: #862959;;
}
.bg-full-res {
  background: #533e79;
  color: #ae9bce;
}

.favorite-btn {
  outline: none;
  border: none;
  font-size: 20pt;
}

.favorite-btn.fave {
  color: red;
}

.cart-btn {
  outline: none;
  border: none;
  font-size: 20pt;
  background-color: transparent;
  background: none;
}

/* accordion */
.accordion-button:after {
  order: -1; 
  margin-left: 0; 
  margin-right:0.5em; 
}


#patient__info.card, #select__product.card, #scheduling__selfpay.card {
  border: none;
}

button.accordion-button:not(.collapsed) {
  color: white;
}

/* 
.accordion-button:link, .accordion-button:visited, .accordion-button:hover, .accordion-button:active  {
  background-color: #00cac2 !important;
  color:#FFF !important;
  text-decoration: none !important;
  border: hidden !important;
  box-shadow: 0px !important;     
} */

.accordion-button {
  font-weight: 700;
  text-shadow: 0px 0.5px 0.5px rgba(95, 93, 93, 0.4); 
}

.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  background:#0fb6c7;
  color: white;
}

button.accordion-button.collapsed {
  background: #0fb6c7;
  color: white;
}

.accordion-button::after {
  transform: rotate(-90deg) !important;
  color:white;
}

.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='eeeeee' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
  transform: rotate(0deg) !important;
  color:white;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='eeeeee' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}


/* ------------------------------ Import Patients ----------------------------- */

.import-patient {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-width: 4px;
  border-radius: 2px;
  min-width: 45%;
  max-width: 45%;
  border-color: rgb(0, 202, 194);
  border-style: dashed;
  background-color: rgb(164, 226, 224);
  color: rgb(75, 76, 77);
  outline: none;
  transition: border 0.24s ease-in-out 0s;
  position: relative;
  cursor: pointer;

}





/* ------------------------------ Responsive media queries / Breakpoints ----------------------------- */


/* Mobile devices */
@media only screen and (max-width: 576px) and (min-width: 320px){

  body {
    font-size: 1.25rem;
  }

  .nav-right-mobile {
    display: block;
    text-align: center;
  }
  
  .dropdown-menu {
    width:100% !important;
  }


  .navbar, .sidebar-heading {
    height: 4rem;
  }

  .md-img {
    min-width: 250px;
  }

  .brand-logo {
    height: 5rem;
  }
  .continue-shopping h3 {
    margin-left: 0;
    font-size: 1.85rem;
  }

  .items-info {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
  }

  .title,
  .add-minus-quantity,
  .price,
  .remove-item {
    padding-left: 2rem;
  }

  .items-info .product-img {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }

  .add-minus-quantity {
    margin: 2rem 0 2rem 0;
    justify-content: flex-start;
  }

  .price {
    justify-content: flex-start;
    margin-bottom: 2rem;
  }

  .price h3::before {
    content: "Price: ";
  }

  .remove-item {
    justify-content: flex-start;
  }

  .card-total {
    margin-bottom: 2rem;
    text-align: center;
  }

  .card-total button {
    margin-bottom: 3rem;
  }

  th, tr, td {
    padding: 2px;
    font-size: 1.25rem;
    vertical-align: middle;
  }

  .home-action-cards{
    display: flex;
    direction: column;
  }
  .service-1, .service-4 {
    display: none;
  }

  .cta-action {
    font-size: .5rem;
  }

  .action-btns {
    padding: 8px 0px;
  }

  /* #bill-toggle-switch.form-check.form-switch {
    width: 100%;
  } */
}

/* iPads, Tablets */
@media (max-width: 768px) {

  #book__service .service-1, .service-4 {
    display: none;
  }

  body {
    font-size: 1.25rem;
  }

  .nav-right-mobile {
    display: block;
    text-align: center;
  }
  
  .dropdown-menu {
    width:100% !important;
  }


  .navbar, .sidebar-heading {
    height: 4rem;
  }


  .md-img {
    min-width: 250px;
  }

  .brand-logo {
    height: 5rem;
  }
  .continue-shopping h3 {
    margin-left: 0;
    font-size: 1.85rem;
  }

  .items-info {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
  }

  .title,
  .add-minus-quantity,
  .price,
  .remove-item {
    padding-left: 2rem;
  }

  .items-info .product-img {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }

  .add-minus-quantity {
    margin: 2rem 0 2rem 0;
    justify-content: flex-start;
  }

  .price {
    justify-content: flex-start;
    margin-bottom: 2rem;
  }

  .price h3::before {
    content: "Price: ";
  }

  .remove-item {
    justify-content: flex-start;
  }

  .card-total {
    margin-bottom: 2rem;
    text-align: center;
  }

  .card-total button {
    margin-bottom: 3rem;
  }

  th, tr, td {
    padding: 2px;
    font-size: 1.25rem;
    vertical-align: middle;
  }

  .home-action-cards{
    display: flex;
    direction: column;
  }

  .service-1, .service-4 {
    display: none;
  }
  #bill-toggle-switch.form-check.form-switch {
    width: 100%;
  }
}

@media only screen and (max-width: 991px) and (min-width: 576px){
  #book__service .service-1, .service-4 {
    display: none;
  }
}

/* Small screens, laptops */
@media (max-width: 1024px) {
  body {
    font-size: 1.25rem;
  }

  .nav-right-mobile {
    display: block;
    text-align: center;
  }
  
  .dropdown-menu {
    width:100% !important;
  }


  .navbar, .sidebar-heading {
    height: 4rem;
  }

  .md-img {
    min-width: 250px;
  }

  .brand-logo {
    height: 5rem;
  }
  .continue-shopping h3 {
    margin-left: 0;
    font-size: 1.85rem;
  }

  .items-info {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
  }

  .title,
  .add-minus-quantity,
  .price,
  .remove-item {
    padding-left: 2rem;
  }

  .items-info .product-img {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }

  .add-minus-quantity {
    margin: 2rem 0 2rem 0;
    justify-content: flex-start;
  }

  .price {
    justify-content: flex-start;
    margin-bottom: 2rem;
  }

  .price h3::before {
    content: "Price: ";
  }

  .remove-item {
    justify-content: flex-start;
  }

  .card-total {
    margin-bottom: 2rem;
    text-align: center;
  }

  .card-total button {
    margin-bottom: 3rem;
  }

  th, tr, td {
    padding: 2px;
    font-size: 1.25rem;
    vertical-align: middle;
  }

  .home-action-cards{
    display: flex;
    direction: column;
  }
  #bill-toggle-switch.form-check.form-switch {
    width: 25%;
  }
}

@media (max-width: 768px) {

  .nav-right-mobile {
    display: block;
    text-align: center;
  }
  
  .dropdown-menu {
    width:100% !important;
  }


  .navbar, .sidebar-heading {
    height: 4rem;
  }
}

/* Desktops, large screens */
 @media (max-width: 1200px) {
  body {
    font-size: 1.25rem;
  }

  .nav-right-mobile {
    display: block;
    text-align: center;
  }
  
  .dropdown-menu {
    width:100% !important;
  }


  .navbar, .sidebar-heading {
    height: 4rem;
  }

  .md-img {
    min-width: 250px;
  }

  .brand-logo {
    height: 5rem;
  }
  .continue-shopping h3 {
    margin-left: 0;
    font-size: 1.85rem;
  }

  .items-info {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
  }

  .title,
  .add-minus-quantity,
  .price,
  .remove-item {
    padding-left: 2rem;
  }

  .items-info .product-img {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }

  .add-minus-quantity {
    margin: 2rem 0 2rem 0;
    justify-content: flex-start;
  }

  .price {
    justify-content: flex-start;
    margin-bottom: 2rem;
  }

  .price h3::before {
    content: "Price: ";
  }

  .remove-item {
    justify-content: flex-start;
  }

  .card-total {
    margin-bottom: 2rem;
    text-align: center;
  }

  .card-total button {
    margin-bottom: 3rem;
  }

  th, tr, td {
    padding: 2px;
    font-size: 1.25rem;
    vertical-align: middle;
  }

  .home-action-cards{
    display: flex;
    direction: column;
  }
  #bill-toggle-switch.form-check.form-switch {
    width: 25%;
  }
} 







