$main-color:  #188581;
$main-light-color:  #00cac2;
$bg-color:  #ffffff;
$bg-light-color: #333642;
$text-color: #ffffff;
$secondary-text-color: #0f0d0d;
$sborder: #cdcdcd;
$gradient: #4d70e2;
$hover-gradient:#6183f5;

#md-homepage {
    padding: 24px;
    margin: 16px 32px;
    
    ::selection {
        background-color: $main-color;
        color: $text-color;
      }
  
  .btn-main {
    background-color: $gradient;
    color: #ffffff;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
    border:none;
    outline: none;
    margin-bottom: 16px;
    cursor: pointer;
  }

  .btn-main:hover {
    background-color: $hover-gradient;
    cursor: pointer;
  }
  .divider-shape {
    height: 0;
    margin: 0.5rem auto;
    overflow: hidden;
    border-top: 3px solid rgba(0, 0, 0, 0.15);
    background-color: #00cac2;
    width: 8rem;
  }
  .home {
    background-size: 640px auto;
    background-color: transparent;
    color: $text-color;
    .intro-cards {
        
      .container {
        .row {

          .col-md-4 {
            .card {
              background-color: $bg-color;
              color: $secondary-text-color;
              min-height: 250px;
              
              
            }
            // .card-title{
            //     margin: 16px 0;
            //     padding: 8px;
            // }
            .card:hover {
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;;
              cursor: pointer;
              transition: 0.4s ease-in;
              .divider-shape {
                background-color: red($color: #000000);
              }
             
            }

            
          }
          
        }
        .card-btn {
            border: none;
            outline: none;
        }
      }
    }
  }
  .services {
    background-color: $text-color;
    color: $bg-color;
    border-bottom: 1px solid $sborder;
    .container {
      .row {
        .col-sm-4 {
          .card:hover {
            background-color: $main-color;
            color: $text-color;
            cursor: pointer;
            transition: background-color 0.5s ease-in, color 0.5s ease-in;
          }
        }
      }
    }
  }
  .work {
    background-color: $text-color;
    color: $bg-color;
    border-bottom: 1px solid $sborder;
  }
  .about {
    background-color: $text-color;
    color: $bg-color;
    border-bottom: 1px solid $sborder;

    .card-img-overlay {
      background-color: rgba(0, 0, 0, 0.7);
    }
    .card:hover .card-img-overlay {
      background-color: rgba(0, 0, 0, 0.3);
    }
    .card1 img {
      max-height: 420px;
      object-fit: cover;
    }
    .card2 img,
    .card3 img {
      max-height: 200px;
      object-fit: cover;
    }
    @media (max-width: 768px) {
      .card1 img {
        max-height: 300px;
      }
      .card2 img,
      .card3 img {
        max-height: 300px;
        width: 100%;
      }
    }
  }
  .testimonial {
    background-color: $text-color;
    color: $bg-color;
    border-bottom: 1px solid $sborder;
  }
  .blog {
    background-color: $text-color;
    color: $bg-color;
    border-bottom: 1px solid $sborder;
  }
  .footer-cta {
    background-color: $bg-light-color;
    color: $text-color;
    border-bottom: 1px solid $sborder;
  }
  .address-bar {
    background-color: $main-color;
    color: $bg-color;
    border-bottom: 1px solid $sborder;
    a {
      color: $text-color;
    }
    a:hover {
      color: $bg-color;
    }
  }
  .footer-bar {
    background-color: $bg-color;
    color: $text-color;
    border-bottom: 1px solid $sborder;
  }
  .darkthemebtn {
    position: fixed;
    right: -0.2rem;
    bottom: 50px;
    background-color: $bg-color;
    color: $text-color;
  }
  .darkthemebtn:hover {
    color: $main-color;
  }
  .back-to-top {
    position: fixed;
    right: -0.2rem;
    bottom: 100px;
    background-color: $bg-color;
    color: $text-color;
    display: none;
  }
  .back-to-top:hover {
    color: $main-color;
  }
}

.dark-theme {
  .services {
    background-color: $bg-color;
    color: $text-color;
    .container {
      .row {
        .col-sm-4 {
          .card {
            background-color: $main-color;
          }
          .card:hover {
            color: $bg-color;
          }
        }
      }
    }
  }
  .work {
    background-color: $bg-color;
    color: $text-color;
  }
  .about {
    background-color: $bg-color;
    color: $text-color;
  }
  .testimonial {
    background-color: $bg-color;
    color: $text-color;
  }
  .blog {
    background-color: $bg-color;
    color: $text-color;
  }
  
  .darkthemebtn {
    background-color: $text-color;
    color: $bg-color;
  }
}
