/* ------------------------------ Font Styles ----------------------------- */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&family=Nunito:wght@400;600;700&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Open+Sans:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,500;1,600&display=swap');

/* font-family: 'Lato', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Work Sans', sans-serif;
font-family: 'Nunito', sans-serif;
font-family: 'Roboto', sans-serif; 
font-family: 'Montserrat', sans-serif;
font-family: 'Open Sans', sans-serif;
*/
/* ------------------------------ Defaults ----------------------------- */

:root {
  --header-height: 3rem;
  --primary-color: #00cac2;
  --sec-color: #4d70e2;
  --white-color: #fff;
  --off-cyan:#EFFBFB;
  --off-white:#F3F0F7;
  --body-font:  'Lato', sans-serif;
  --normal-font-size: 1rem;
  --z-navbar: 50;
  --z-sidebar:100;
  --z-main:10;
  --offcanvass-width:270 px;
  --topNavbarHeight: 56px;
  --lah-gradient: linear-gradient(225deg, #00cac2, #4d70e2);
  --btn-hover-gradient:linear-gradient(90deg, rgb(49, 132, 201) 0%, rgb(11, 167, 179) 35%, rgba(0,212,255,1) 100%);
  --gradient: linear-gradient(225deg, #00cac2, #4d70e2);
}

*, ::before,::after{
  box-sizing: border-box;
}


html {
    scroll-behavior: smooth;
}


body {
    margin: 0;
    overflow-x: hidden;
    height: 100vh;
    width: 100%;
    font-family: var(--body-font);
    font-size:var(--normal-font-size);
    font-weight: 400;
    overflow:hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
  cursor: pointer;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

label {
  font-size: 8pt;
}

strong {
  font-weight: 600;
}

.btn {
  padding: 2.5px;
}


/* || UTILITY CLASSES */

.remove-button {
  display: none;
}

.offscreen {
  position: absolute;
  left: -10000px;
}

.split-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  gap: 1rem;
}

.flex-col {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.35rem;
  width: 100%;
}

/* input:invalid {
  outline: 2px solid red;
  background-color: rgb(255, 255, 139);
} */


/* || GENERAL STYLES */

/* body {
  font-family: 'Nunito', sans-serif;
  min-height: 100vh;
  background: radial-gradient(skyblue, darkblue);
  padding: 1rem;
} */

/* input,
button,
textarea,
select {
  font: inherit;
} */

/* #root {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
} */

.progress-container {
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding: 1rem;
  border: 1px solid #000;
  border-radius: 10px;
  background-color: whitesmoke;
  position: relative;
}

.barmarker-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}

.barmarker {
  width: 100%;
  display: grid;
  place-content: center;
}

.barmarker:is(:not(:last-child)) {
  border-right: 1px solid #000;
}

.progress {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 32px;
  margin-top: 5px;
}

.progress::-webkit-progress-bar {
  background-color: lightcoral;
  border-radius: 10px;
}

.progress::-webkit-progress-value {
  background-color: limegreen;
  border-radius: 10px;
}

.progress::after {
  content: attr(value) '%';
  position: absolute;
  width: 56px;
  top: 53%;
  left: 50%;
  margin-left: -28px;
  font-weight: bold;
}
/* 
.form {
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding: 0.75rem;
  border: 1px solid #000;
  border-radius: 10px;
  background-color: whitesmoke;
} */

.form-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.button-container {
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
}

.button {
  padding: 0.5rem;
  border-radius: 10px;
}

/* .button:focus {
  outline: 2px solid gold;
} */

/* input,
select {
  padding: 0.5rem;
  border-radius: 10px;
  width: 100%;
} */

/* input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
} */

ul {
  list-style-type: none;
  margin-bottom: 1rem;
}

.gradient-text {
  background: var(--gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}