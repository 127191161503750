/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: lightblue;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  font-size: 14px;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
} */

.circle {
  width: 29px;
  height: 29px;
  line-height: 25px;
  background-color: white;
  color: white;
  color: black;
  border-radius: 100%;
  border: 2px solid rgb(77, 112, 226);
  -moz-background-clip: padding-box;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  text-align: center;
  position: relative;
  z-index: 2;
}

.stepWrapper {
  display: flex;
  margin-bottom: 20px;
  font-size: 12px;
  text-align: center;
}

.stepBlock .circleWrapper {
  padding: 0px 30px;
  position: relative;
}

.selected .circle {
  color: white;
  background-color:  rgb(77, 112, 226);
  border-color: rgb(77, 112, 226);
  border: none;
}

.gray-circle {
  background-color: gray;
  border: none;
}



.stepBlock:not(:last-child) > .circleWrapper::after {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: gray;
  margin: auto;
  z-index: 1;
}

.stepBlock:not(:last-child) > .circleWrapper::after {
  height: 3px;
}

.stepBlock:not(:last-child).selected > .circleWrapper::after, .stepBlock:not(:last-child).selected ~ .stepBlock:not(:last-child) > .circleWrapper::after {
  height: 2px;
  opacity: 0.6;
  background-color: rgb(77, 112, 226);
}

.primaryButton {
  padding: 10px 25px;
  background-color: rgb(77, 112, 226);
  border: none;
  margin: 10px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.primaryButton:disabled {
  opacity: 0.5;
}